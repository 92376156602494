import React, { useState } from 'react'
import axios from 'axios'
import RenderRazorpay from './RenderRazorpay'
import { baseUrl } from '../../services/PostAPI'
import { Button } from 'primereact/button'

const PaymentComponent = (props) => {
  const { plansList, plot, handlePayment } = props.paymentProps

  const [displayRazorpay, setDisplayRazorpay] = useState(false)
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null
  })


  const handleCreateOrder = async (plan) => {
    const token = localStorage.getItem('token')
    setDisplayRazorpay(false);
    try {
      const response = await axios.post(
        `${baseUrl}/payment/create-order`,
        {
          plan: {
            id: plan?.id ?? '',
            amount: plan?.amount ?? ''
          },
          plotId: plot
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      const data = response.data
      if (data && data.orderId) {
        setOrderDetails({
          orderId: data.orderId,
          currency: data.currency || 'INR',
          amount: data.amount || 0
        })
        setDisplayRazorpay(true)
      }
    } catch (error) {
      console.log('Error creating order', error)
    }
  }

  const PlanHeader = ({ planName }) => (
    <div className="text-900 font-medium text-xl mb-2 text-center">{planName}</div>
  )
  const PlanPrice = ({ amount }) => (
    <>
      <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
      <div className="flex align-items-center">
        <span className="font-bold text-2xl ">{amount ?? ''}</span>
        <span className="ml-2 font-medium text-600">
          <i className="pi pi-indian-rupee"></i>
          <span className='text-xl'> per plot</span>
        </span>
      </div>
      <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
    </>
  )

  const PlanFeatures = ({ availableCount }) => (
    <ul className="list-none p-0 m-0 flex-grow-1">
      <li className="flex align-items-center mb-3">
        <i className="pi pi-check-circle text-green-500 mr-2"></i>
        <span className="text-900">Free PDF Download</span>
      </li>
      {/* <li className="flex align-items-center mb-3">
        <i className="pi pi-check-circle text-green-500 mr-2"></i>
        <span className="text-900">Get 7/12 Area</span>
      </li> */}
      <li className="flex align-items-center mb-3">
        <i className="pi pi-check-circle text-green-500 mr-2"></i>
        <span className="text-900">Free Get Direction</span>
      </li>
      <li className="flex align-items-center mb-3">
        <i className="pi pi-check-circle text-green-500 mr-2"></i>
        <span className="text-900">{availableCount ?? ''} views</span>
      </li>
    </ul>
  )

  const PlanButton = ({ onClick }) => (
    <>
      <hr className="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
      <Button
        onClick={onClick}
        label="Select Plan"
        class="p-3 w-full bg-primary border-none border-round-md cursor-pointer"
      ></Button>
    </>
  )

  return (
    <div className="w-full">
      <div className="surface-grou px-4 py-6 md:px-6">
        <div className=" font-bold text-4xl mb-4 text-center">
          Select Plan
        </div>
        {/* <div className="text-700 text-xl mb-6 text-center line-height-3">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit
          numquam eligendi quos.
        </div> */}

        <div className="grid">
          {plansList?.map((plan, index) => (
            <div key={index} className="col-12 md:col-12">
              <div className="p-3 h-full">
                <div
                  className="shadow-2 p-5 h-full flex flex-column surface-card"
                  style={{ borderRadius: '6px' }}
                >
                  <PlanHeader planName={plan.name} />
                  <PlanPrice amount={plan?.amount} />
                  <PlanFeatures availableCount={plan?.availableCount} />
                  <PlanButton onClick={() => handleCreateOrder(plan)} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {displayRazorpay && (
        <RenderRazorpay
          amount={orderDetails.amount}
          currency={orderDetails.currency}
          orderId={orderDetails.orderId}
          keyId={process.env.REACT_APP_RAZORPAY_KEY_ID}
          keySecret={process.env.REACT_APP_RAZORPAY_KEY_SECRET}
          handlePayments={handlePayment}
          plot={plot}
        />
      )}
    </div>
  )
}

export default PaymentComponent
