import React from 'react' 
import './index.css'
import { Link } from 'react-router-dom'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import { useLocation } from 'react-router-dom'

export default function FooterComponent() {
  const location = useLocation()

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  
  const shouldShowFooter = (location.pathname !== '/' && location.pathname !== '/home') || !isMobile;

  return ( 
    shouldShowFooter && (

    <footer
     className=" main-footer-container px-2"
    >

      <div className="grid grid-nogutter">
        <div className="col-12 md:col-6 lg:col-3 p-2">
          <div>
            <h2 className=" font-bold ">Our Office</h2>
            <p className="flex align-items-center pt-2">
              <i
                className="pi pi-map-marker mr-2 icon-style"
              ></i>
              <a
                href="https://www.google.com/maps/place/Multigenesys+Software+Private+Limited/@18.5997769,73.7370907,14.32z/data=!4m20!1m13!4m12!1m4!2m2!1d73.7574912!2d18.6023936!4e1!1m6!1m2!1s0x3bc2bb1c150feea7:0x6b6e0198335717d8!2smultigenesys!2m2!1d73.7484899!2d18.5905449!3m5!1s0x3bc2bb1c150feea7:0x6b6e0198335717d8!8m2!3d18.5905449!4d73.7484899!16s%2Fg%2F11g_v6fslg?entry=ttu&g_ep=EgoyMDI0MTAwNS4yIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer" 
                className='footer-links-style'
              >
                Hinjawadi Phase 1, Suratwala Plazo
              </a>
            </p>
            <p className="flex align-items-center">
              <i
                className="pi pi-phone mr-2 icon-style"
              ></i>
              <a
                href="tel:+918421313113"
                className="text-blue-500 footer-links-style"
              >
                + 91 84213-13113 
              </a>
            </p>
            <p className="flex align-items-center">
              <i
                className="pi pi-envelope mr-2 icon-style"
              ></i>
              {'   '}{' '}
              <a
                href="mailto:support@bhoomi22.com"
                className="text-blue-500 footer-links-style"
              >
                support@bhoomi22.com.
              </a>
            </p>
          </div>
        </div>

        <div className="col-12 md:col-6 lg:col-3 p-2">
          <div className="flex flex-column text-left line-height-4">
            <h2 className=" font-bold ">Customer Policies</h2>
            <Link
              to="/about-us"
              onClick={handleLinkClick}
              className="footer-text"
            >
              About Us
            </Link>
            <Link
              to="/terms-conditions"
              onClick={handleLinkClick}
              className="footer-text"
            >
              Terms and Conditions
            </Link>
            <Link
              to="/privacy-policy"
              onClick={handleLinkClick}
              className="footer-text"
            >
              Privacy Policy
            </Link>
            <Link
              to="/shipping-policy"
              onClick={handleLinkClick}
              className="footer-text"
            >
              Shipping Policy
            </Link>
            <Link
              to="/refund-policy"
              onClick={handleLinkClick}
              className="footer-text"
            >
              Refund Policy
            </Link>
            <Link
              to="/contact-us"
              onClick={handleLinkClick}
              className="footer-text"
            >
              Contact Us
            </Link>
          </div>
        </div>

        <div className="col-12 md:col-6 lg:col-3 p-2">
          <div className="flex flex-column text-left">
            <h2 className=" font-bold ">Business Hours</h2>
            <p>Monday-Friday</p>
            <h4
              className="font-semibold "
              style={{
                marginTop: '0',
                marginBottom: '0.5rem',
                lineHeight: '1.2'
              }}
            >
              09:00 am - 07:00 pm
            </h4>
           
            <p>Saturday and Sunday</p>
            <h4
              className="font-semibold"
              style={{
                marginTop: '0',
                marginBottom: '0.5rem',
                lineHeight: '1.2'
              }}
            >
              Closed
            </h4>
          </div>
        </div>

        <div className="col-12 md:col-6 lg:col-3 p-2">
          <div className="flex flex-column text-left">
            <h2 className=" font-bold ">Why We Best?</h2>
            <p>
            At Bhoomi22, we prioritize accuracy and reliability in land information. Our dedicated customer service teams are committed to providing you with precise land details, ensuring that you have the most up-to-date and trustworthy data at your fingertips.
            </p>
          </div>
        </div>
  
        <div className="col-12 text-center  ">
          <div className='text-xs updating-disclaimer'>We are updating data for some locations on our website. You will be notified once the updates are complete.</div>
        <hr/>
        <div style={{fontSize:"13px" }} className='p-3 bg-gray-200 mb-1'>
         
           All Rights Reserved by   ©
          <a href="https://bhoomi22.com/" className='footer-links-style'>
            <span className='footer-bhoomi-text'>Bhoomi22.com</span>
          </a>.  &nbsp;
          Designed and developed by
          <a
            href="https://multigenesys.com/"
            className='footer-links-style'
          >
            <span className='footer-bhoomi-text'>
             &nbsp; MultiGenesys Software Pvt Ltd
            </span>
          </a>
          .
        </div>
        </div>
      </div>
    </footer>)
  )
}
