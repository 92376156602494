import React, { useState, useMemo } from 'react'
import './index.css'
import 'primeicons/primeicons.css'
import { useForm } from 'react-hook-form'
import MzInput from '../../common/MzForm/MzInput'
import { FORM_FIELDS_NAME } from './constant'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import MzPhoneInput from '../../common/MzForm/MzPhoneInput'
import MzTextarea from '../../common/MzForm/MzTextArea'
import { createcontactUsRecord } from '../../redux/action/contactUs'
import { useDispatch } from 'react-redux'

const ContactUsComponent = (props) => {
  const dispatch = useDispatch()
  const { formFieldValueMap } = props.contactusProps
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap
    }, [formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const [focusedField, setFocusedField] = useState(null)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLevelError] = useState(false)

  const inputStyles = {
    width: '100%',
    padding: '10px',
    border: '1px solid #4caf50',
    borderRadius: '5px',
    fontSize: '16px',
    marginTop: '0.5%',
    marginBottom: '2%',
    transition: 'border-color 0.3s ease, box-shadow 0.3s ease'
  }

  const focusStyles = {
    borderColor: '#4caf50',
    outline: 'none',
    boxShadow: 'hsl(160, 85%, 36%) 0px 0px 0px 1px'
  }

  const handleFocus = (field) => {
    setFocusedField(field)
  }

  const handleBlur = () => {
    setFocusedField(null)
  }

  const getInputStyles = (field) => {
    return focusedField === field
      ? { ...inputStyles, ...focusStyles }
      : inputStyles
  }

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name]?.message}</small>
    )
  }

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)
      await dispatch(createcontactUsRecord(data))
      reset({
        fname: '',
        lname: '',
        email: '',
        mobile: '',
        address: '',
        msg: ''
      })
      setIsLoading(false)
    } catch (error) {
      console.error('Error submitting form:', error)
      setIsLoading(false)
    }
  }

  const navigateToHomePage = () => {
    navigate('/')
  }

  return (
    <div className="main-container mt-8">
      
      <div
        className="flex flex-column align-items-center justify-content-center heading-bg-container py-5"
      >
        <div className="py-4 px-4 text-center">
          <h1
            className="font-bold text-4xl md:text-5xl lg:text-6xl mb-4"
          >
            Contact Us
          </h1>
          <p className="text-lg text-700 font-medium lg:font-normal">
          Connect with us today and discover how Bhoomi22 can turn challenges into opportunities for your land and property management needs.
          </p>
        </div>
      </div>

      <div className="grid m-0 justify-content-between pt-5 ml-4 mr-4 lg:ml-8 lg:mr-8">
        <div className="col-12 p-0 md:col-6 lg:col-6 flex flex-column align-items-start lg:w-5 w-full pt-5 mb-6 lg:mb-0">
          <div className="flex align-items-start mb-4">
            <div
              className="border-circle w-3rem h-3rem mr-3 flex align-items-center justify-content-center contact-icon-container"
            >
              <a
                href="https://www.google.com/maps/place/Multigenesys+Software+Private+Limited/@18.5997769,73.7370907,14.32z/data=!4m20!1m13!4m12!1m4!2m2!1d73.7574912!2d18.6023936!4e1!1m6!1m2!1s0x3bc2bb1c150feea7:0x6b6e0198335717d8!2smultigenesys!2m2!1d73.7484899!2d18.5905449!3m5!1s0x3bc2bb1c150feea7:0x6b6e0198335717d8!8m2!3d18.5905449!4d73.7484899!16s%2Fg%2F11g_v6fslg?entry=ttu&g_ep=EgoyMDI0MTAwNS4yIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <i className="pi pi-map-marker text-white text-2xl"></i>
              </a>
            </div>

            <div>
              <h2
                className="text-xl font-medium lg:font-bold md:font-bold"
              >
                Address
              </h2>
              <a
                href="https://www.google.com/maps/place/Multigenesys+Software+Private+Limited/@18.5997769,73.7370907,14.32z/data=!4m20!1m13!4m12!1m4!2m2!1d73.7574912!2d18.6023936!4e1!1m6!1m2!1s0x3bc2bb1c150feea7:0x6b6e0198335717d8!2smultigenesys!2m2!1d73.7484899!2d18.5905449!3m5!1s0x3bc2bb1c150feea7:0x6b6e0198335717d8!8m2!3d18.5905449!4d73.7484899!16s%2Fg%2F11g_v6fslg?entry=ttu&g_ep=EgoyMDI0MTAwNS4yIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <p className="text-md text-gray-700">
                  Office No. 612, B Wing, 6th floor,
                  <br />
                  Suratwala Mark Plazzo, Hinjawadi,
                  <br />
                  Pune, Maharashtra 411057.
                </p>
              </a>
            </div>
          </div>
          <div className="flex align-items-start mb-4">
            <div
              className="border-circle w-3rem h-3rem mr-3 flex align-items-center justify-content-center contact-icon-container"
            >
              <a href="tel:+918421313113">
                <i className="pi pi-phone text-white text-2xl"></i>
              </a>
            </div>

            <div>
              <h2
                className="text-xl font-medium lg:font-bold md:font-bold"
              >
                Phone
              </h2>
              <p className="text-md text-gray-700">
                <a
                  href="tel:+918421313113"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  +91 84213-13113
                </a>
              </p>
            </div>
          </div>

          <div className="flex align-items-start">
            <div
              className="border-circle w-3rem h-3rem mr-3 flex align-items-center justify-content-center contact-icon-container"
            >
              <a href="mailto:support@bhoomi22.com">
                <i className="pi pi-envelope text-white text-2xl"></i>
              </a>
            </div>

            <div>
              <h2
                className="text-2xl font-medium lg:font-bold md:font-bold"
              >
                Email
              </h2>
              <p className="text-md text-gray-700">
                {' '}
                <a
                  href="mailto:support@bhoomi22.com"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  support@bhoomi22.com.
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          className="col-12 md:col-6 lg:col-6 flex flex-column w-full lg:w-6  p-2 lg:pt-4 border-round-sm shadow-6 contact-form-bg"
        >
          <h1
            className="text-2xl text-center mb-4"
          >
            Get In Touch
          </h1>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-2 w-full px-2 lg:px-4 md:mt-5"
          >
            <div className="card">
              <div className="grid">
                <div className="col-12 md:col-6 margin-bottom-input">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.FIRST_NAME.name}
                    label={FORM_FIELDS_NAME.FIRST_NAME.label}
                    placeholder={FORM_FIELDS_NAME.FIRST_NAME.placeholder}
                    type={FORM_FIELDS_NAME.FIRST_NAME.type}
                    isError={errors[FORM_FIELDS_NAME.FIRST_NAME.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.FIRST_NAME.name
                    )}
                    rules={FORM_FIELDS_NAME.FIRST_NAME.rules}
                    wrapperClass={'p-float-label'}
                  />
                </div>
                <div className="col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.LAST_NAME.name}
                    label={FORM_FIELDS_NAME.LAST_NAME.label}
                    placeholder={FORM_FIELDS_NAME.LAST_NAME.placeholder}
                    type={FORM_FIELDS_NAME.LAST_NAME.type}
                    isError={!!errors[FORM_FIELDS_NAME.LAST_NAME.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.LAST_NAME.name
                    )}
                    rules={FORM_FIELDS_NAME.LAST_NAME.rules}
                    wrapperClass="p-float-label"
                    style={getInputStyles(FORM_FIELDS_NAME.LAST_NAME.name)}
                    onFocus={() => handleFocus(FORM_FIELDS_NAME.LAST_NAME.name)}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.EMAIL.name}
                    label={FORM_FIELDS_NAME.EMAIL.label}
                    placeholder={FORM_FIELDS_NAME.EMAIL.placeholder}
                    type={FORM_FIELDS_NAME.EMAIL.type}
                    isError={!!errors[FORM_FIELDS_NAME.EMAIL.name]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.EMAIL.name)}
                    rules={FORM_FIELDS_NAME.EMAIL.rules}
                    wrapperClass="p-float-label"
                    style={getInputStyles(FORM_FIELDS_NAME.EMAIL.name)}
                    onFocus={() => handleFocus(FORM_FIELDS_NAME.EMAIL.name)}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-12 md:col-6">
                  <MzPhoneInput
                    control={control}
                    name={FORM_FIELDS_NAME.MOBILE.name}
                    label={FORM_FIELDS_NAME.MOBILE.label}
                    placeholder={FORM_FIELDS_NAME.MOBILE.placeholder}
                    type={FORM_FIELDS_NAME.MOBILE.type}
                    isError={!!errors[FORM_FIELDS_NAME.MOBILE.name]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.MOBILE.name)}
                    rules={FORM_FIELDS_NAME.MOBILE.rules}
                    wrapperClass="p-float-label"
                    style={getInputStyles(FORM_FIELDS_NAME.MOBILE.name)}
                    onFocus={() => handleFocus(FORM_FIELDS_NAME.MOBILE.name)}
                    onBlur={handleBlur}
                    country="in"
                  />
                </div>
                <div className="col-12">
                  <MzTextarea
                    control={control}
                    name={FORM_FIELDS_NAME.ADDRESS.name}
                    label={FORM_FIELDS_NAME.ADDRESS.label}
                    placeholder={FORM_FIELDS_NAME.ADDRESS.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.ADDRESS.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.ADDRESS.name
                    )}
                    rules={FORM_FIELDS_NAME.MESSAGE.rules}
                  />
                </div>
                <div className="col-12">
                  <MzTextarea
                    control={control}
                    name={FORM_FIELDS_NAME.MESSAGE.name}
                    label={FORM_FIELDS_NAME.MESSAGE.label}
                    placeholder={FORM_FIELDS_NAME.MESSAGE.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.MESSAGE.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.MESSAGE.name
                    )}
                    rules={FORM_FIELDS_NAME.MESSAGE.rules}
                  />
                </div>
              </div>
            </div>

            <div className="grid justify-content-center mt-3 mb-5">
              <div className="col-12 md:col-4">
                <Button
                  label="Cancel"
                  className="w-full"
                  severity="secondary"
                  onClick={navigateToHomePage}
                  disabled={isLoading || isPageLevelError}
                />
              </div>
              <div className="col-12 md:col-4">
                <Button
                  type="submit"
                  label="Submit"
                  className="w-full"
                  disabled={isLoading || isPageLevelError}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactUsComponent
